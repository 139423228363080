<style lang="less" scoped>
.aboutUs {
  width: 100%;
  background: #070911;
}
.aboutUs-details {
  width: 76.7%;
  height: 100%;
  max-width: 1104px;
  margin: 0 auto;
  // padding: 196px 0;
  padding: 96px 0;
  color: rgba(222, 230, 255, 1);
  &-first {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-left {
      width: 49.8%;
      img {
        width: 100%;
      }
    }
    &-right {
      width: 43.1%;
      p {
        margin: 0;
        font-family: "PingFangSC-Regular", "PingFang SC";
        color: rgba(222, 230, 255, 1);
        font-weight: 400;
        &:nth-child(1) {
          font-size: 30px;
          line-height: 42px;
          letter-spacing: 4px;
        }
        &:nth-child(2) {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 4px;
          margin: 5px 0 28px;
        }
        &:nth-child(3) {
          font-size: 16px;
          line-height: 40px;
          letter-spacing: 2px;
        }
      }
    }
  }
  &-second {
    margin-top: 120px;
  }
  .title,
  .normal {
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 2px;
    text-align: justify;
  }
  .title {
    font-size: 20px;
  }
  &-third {
    margin-top: 101px;
    position: relative;
    img {
      width: 100%;
    }
    &-main {
      position: absolute;
      top: 70px;
      left: 637px;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 4px;
      }
      p:nth-child(2) {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 4px;
      }
      p:nth-child(3),
      p:nth-child(5) {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
      }
      p:nth-child(4),
      p:nth-child(6) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>

<template>
  <div class="aboutUs">
    <div class="aboutUs-details">
      <div class="aboutUs-details-second">
        <p class="normal">
          Consulting – To provide consulting support on areas such as pairing resources, providing relevant market analysis, suggesting investment and operational arrangements. <br /> <br />
          Training – To provide training and continuing professional development in collaboration with Chinese local authorities, institutes and awarding bodies across the world in sectors including creative industries, finance, marketing, cross cultural management, e-commerce, languages and business operations.<br /> <br />
          Funding – To provide opportunities to meet investors and apply for specific funds to ensure project and business development and establish a virtuous circle with expert support.
        </p>
      </div>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonActivies from '../components/common-activities';
import CommonFooter from '../components/common-footer';
export default {
  name: 'aboutUs-details',
  data () {
    return {
    }
  },
  created () {
    if (this.$Cookies.get('cecc-lang') !== 'en') this.$router.push({
      name: 'masterView'
    })
  },
  mounted () {
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    }
  },
  components: {
    CommonActivies,
    CommonFooter
  },
}
</script>
